<template>
	<!-- 记事信息 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">协调记录</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small"
						@click="addOrUpdateHandle()">添加</el-button>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="协调时间" class="selFormItem">
							<el-date-picker class="selDateInput" v-model="recContentTime" value-format="yyyy-MM-dd"
								type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:7px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							v-if="showSearch">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div class="line" style="margin-top:-25px; margin-left:0px;width:100%;"></div>
		<el-card style="margin-top:-40px;height:575px">
			<el-row :gutter="20">
				<el-col :span="8" style="margin-top: 20px;" v-for="(item, index) in dataList">
					<el-card style="height:240px;background-color:rgb(241, 243, 243);width:360px">
						<el-row>
							<el-col :span="12">
								<span style="color: #00428e; margin-right: 10px; font-weight: bold;">协调时间</span>
								<span style="color: #727580;">{{ item.coordinateTime }}</span>
							</el-col>
							<el-col :span="12">
								<span style="color: #00428e; margin-right: 10px; font-weight: bold;">登记人</span>
								<span style="color: #727580;">{{ item.registerMan }}</span>
							</el-col>
							<el-col :span="24" style="padding-top: 20px;">
								<span style="color: #00428e; margin-right: 10px; font-weight: bold;">双方人员</span>
								<span style="color: #727580;">{{ item.coordinateMan }}</span>
							</el-col>
							<el-col :span="24">
								<div class="noteTitle">重要事项</div>
								<div class="noteContent" style="height: 50px; line-height: 30px; overflow: hidden;">
									{{ item.coordinateContent }}
								</div>
								<div style="text-align: right; margin-top: 20px;" v-if="showDelete || showEdit">
									<el-button v-preventReClick type="text" size="mini"
										@click="addOrUpdateHandle(item.recNo)" v-if="showEdit">修改</el-button>
									<el-button v-preventReClick type="text" size="mini" @click="deleteHandle(item.recNo)"
										v-if="showDelete">删除</el-button>
								</div>
							</el-col>
						</el-row>
					</el-card>
				</el-col>
			</el-row>
			<div style="margin-top:520px">
				<el-pagination class="my-pagination" @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
					:current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="limit" :total="totalCount"
					layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>
		</el-card>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>
	</div>
</template>
<script>
import AddOrUpdate from './coordinate-add-or-update'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			showAdd: false,
			showEdit: false,
			showDelete: false,
			pageSpan: 12,
			form: {},
			// subjectList:[],
			dataList: [],
			recContentTime: [],
			// subjectNo:"",
			limit: 6,
			page: 1,
			totalCount: 0,
			addOrUpdateVisible: false
		};
	},
	components: {
		AddOrUpdate
	},
	mounted() {
		this.showSearch = $common.isAuth('coordinate:search');
		this.showAdd = $common.isAuth('coordinate:add');
		this.showEdit = $common.isAuth('coordinate:edit');
		this.showDelete = $common.isAuth('coordinate:delete');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
	},
	methods: {
		selGetDataList() {
			this.page = 1;
			this.getDataList();
		},
		resetFormData() {
			this.recContentTime = [];
		},
		currentChangeHandle(val) {
			this.page = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.limit = val;
			this.page = 1;
			this.getDataList()
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/coordinaterecord/list",
					method: "get",
					params: {
						limit: this.limit,
						page: this.page,
						situationType: this.situationType,
						beginTime: this.recContentTime[0] ? this.recContentTime[0] : '',
						endTime: this.recContentTime[1] ? this.recContentTime[1] : '',
						workNo: $common.getItem("workNo")
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalCount = data.body.totalCount;
					}
				});
			}
		},
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.situationType)
			})
		},
		deleteHandle(id) {
			this.$confirm(`您确定要删除选择的数据么?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/coordinaterecord/delete/" + id,
					method: 'post',
					data: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.noteTitle {
	font-weight: bold;
	color: #00428e;
	border-bottom: 1px solid #00428e;
	padding-bottom: 10px;
	margin-top: 20px;
}

.noteContent {
	color: #727580;
	margin-top: 10px;
	line-height: 25px;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
scope
